<template>
    <div>
        <div class="result_wpr">
            <div class="actions">
                <ul>
                    <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                        {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                        <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="playbook-user-per-page-filter" />
                    </li>
                    <li>
                        <input type="text" placeholder="Search" :disabled="playbookQuestionLoader" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                        <i class="fas fa-search"></i>
                    </li>
                    <li class="list_info">
                        {{ playbookQuestion.from ? playbookQuestion.from : 0 }} - {{ playbookQuestion.to ? playbookQuestion.to : 0 }} of <span>{{ playbookQuestion.total ? playbookQuestion.total : 0 }}</span>
                    </li>
                </ul>
            </div>
            <div v-if="playbookQuestionLoader"><line-loader /></div>
            <table class="standard">
                <thead>
                    <tr>
                        <th></th>
                        <th>Date Created</th>
                        <th>E-mail</th>
                        <th>Question</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody  v-if="playbookQuestion.total">
                    <tr v-for="(question, q) of playbookQuestion.data" :key="q">
                        <td></td>
                        <td>{{ moment.utc(question.created_at).local().format('ll')}}</td>
                        <td>
                            <div class="email_wpr">{{ question.email }}</div>
                        </td>
                        <td>{{ question.question}}</td>
                        <td><button :class="question.status == 1 ? 'failed' : 'success'">{{ question.status == 1 ? 'Pending' : 'Answered' }}</button></td>
                        <td>
                            <ul class="action_list">
                                <li @click="resendPlaybook(question.user_id)"><i class="fas fa-envelope"></i></li>
                                <li @click="handleAnswerPlaybook(question)"><i class="fas fa-reply"></i></li>
                                <li @click="handleDeleteQuestion(question)"><i class="fas fa-trash-alt"></i></li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="4" class="px-4">No Records Found</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="pagination pb-4 mt-4" v-show="playbookQuestion.total">
            <pagination v-model="params.page" :pages="playbookQuestion.last_page" @update:modelValue="handlePagination" />
        </div>
        <resend-login v-model="resendLogin" :playbook="playbook" :contact-id="selectedContactId" />
        <answer-component v-model="answerFAQ" :question="selectedQuestion" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const PageFilter = defineAsyncComponent(() => import('@/components/PageFilter'))
    const LineLoader = defineAsyncComponent(() => import('@/components/LineLoader'))
    const ResendLogin = defineAsyncComponent(() => import('@/pages/playbook/components/ResendLogin'))
    const AnswerComponent = defineAsyncComponent(() => import('@/pages/playbook/components/Answer'))

    import moment from 'moment'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Pagination from '@hennge/vue3-pagination'

    export default {
        name: 'Playbook User',

        data () {
            return {
                params: {
                    page: 1,
                    per_page: 5,
                    search: '',
                },
                isTyping: false,
                selectedContactName: '',
                selectedContactId: 0,
                resendLogin: false,
                moment,
                answerFAQ: false,
                selectedQuestion: {},
            };
        },

        props: {
            playbook: Object,
        },

        components: {
            PageFilter,
            Pagination,
            LineLoader,
            ResendLogin,
            AnswerComponent,
        },

        watch: {
            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getPlaybookQuestions(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getPlaybookQuestions(vm.params);
                        }
                    }
                }
            },

            'params.per_page' () {
                const vm = this;

                vm.params.page = 1;
                vm.getPlaybookQuestions(vm.params);
            },

            playbook (playbook) {
                const vm = this;

                vm.params.playbook_id = playbook.id;
                vm.getPlaybookQuestions(vm.params);
            },
        },

        computed: mapState({
            playbookQuestion: state => state.playbookModule.playbookQuestion,
            playbookQuestionLoader: state => state.playbookModule.playbookQuestionLoader,
        }),

        mounted () {
            const vm = this;
            vm.params.playbook_id = vm.playbook.id;
            vm.getPlaybookQuestions(vm.params);
        },

        methods: {
            ...mapActions({
                getPlaybookQuestions: 'playbookModule/getPlaybookQuestions',
                deletePlaybookQuestions: 'playbookModule/deletePlaybookQuestions',
            }),

            togglePerPageFilter () {
                const vm = this;
                vm.actionList = false;
                vm.bulkActionDropdown = false;
                const filter = vm.$refs['playbook-user-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['playbook-user-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getPlaybookQuestions(vm.params);
            },

            resendPlaybook (contactId) {
                const vm = this;

                vm.resendLogin = true;
                vm.selectedContactId = contactId;
            },

            handleDeleteQuestion (question) {
                const vm = this;
                const options = Helper.swalConfirmOptions('Are you sure?', 'You will not be able to recover these playbook question.');
                      options.preConfirm = () => {
                          return  vm.deletePlaybookQuestions({ id: question.id, playbook_id: question.playbook_id }).then((result) => {
                                      if (result) {
                                          vm.getPlaybookQuestions(vm.params);
                                      }
                                  });
                      }

                Swal.fire(options);
            },

            handleAnswerPlaybook (question) {
                const vm = this;

                vm.selectedQuestion = question
                vm.answerFAQ        = true;
            }
        },
    }
</script>

<style scoped>
    td.action_wpr {
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0;
    }

    .mt-0 {
        margin-top: 0px;
    }

    .modal .modal_footer {
        border-radius: 12px;
    }

    .btn_sm {
        min-width: 60px;
        height: 30px;
        padding: 0 20px;
    }

    .p-0 {
        padding: 0;
    }

    .modal_container.p-0 .modal_header {
        padding: 20px 30px 0;
        width: 100%;
    }

    table.tab-completion {
        border-radius: 14px;
    }

    table.tab-completion thead tr th:first-child,
    table.tab-completion tbody tr td:first-child {
        min-width: 120px;
        text-align: left;
        padding: 20px;
    }

    .result_wpr table.tab-completion thead {
        border: 0px;
        border-bottom: 1px solid #DDD;
    }

    :deep(.action_list) {
        display: flex;
        flex-wrap: wrap;
    }

    :deep(.action_list li) {
        margin-right: 5px;
        cursor: pointer;
    }

    :deep(.action_list li i.fa-envelope) {
        color: #f2a31d;
    }

    :deep(.action_list li i.fa-reply) {
        color: #2f7eed;
    }

    :deep(.action_list li i.fa-trash-alt) {
        color: #eb1414;
    }

    .result_wpr table.standard td:first-child,
    .result_wpr table.standard th:first-child {
        width: auto;
        text-align: left;
    }

    table button {
        padding: 3px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #f2a31e;
        border-radius: 10px;
        color: #fff;
        margin-left: 7px;
    }

    table button.failed {
        background: #f04438;
    }

    table button.success {
        background: #00aa14;
    }

    table.tab-completion {
        border-radius: 14px;
    }

    table.tab-completion thead tr th:first-child,
    table.tab-completion tbody tr td:first-child {
        min-width: 120px;
        text-align: left;
        padding: 20px;
    }

    .result_wpr table.tab-completion thead {
        border: 0px;
        border-bottom: 1px solid #DDD;
    }
</style>
